/* *************************************************
 zero_reports.js
 ***************************************************/
import * as setup from 'src/js/datatables_setup';

$(function () {
  const table = $('#zero-report-table');
  const dataTableOptions = {
    iDisplayLength: 25,
    bProcessing: true,
    sDom: 'ltipr',
    oLanguage: {
      sProcessing: setup.tableStartProcessing(),
      sEmptyTable: "No data available in table",
      sInfo: "Showing _START_ to _END_ of found entries"
    },
    fnPreDrawCallback: function () {
      const show_accounts = table.hasClass('show-accounts');
      table.DataTable().column(0).visible(show_accounts);
      setup.tableStartProcessing();
    },
    fnDrawCallback: function () {
      setup.tableStopProcessing();
    },
    bServerSide: true,
    sAjaxSource: $('#zero-report-table').data('source'),
    sPaginationType: "simple",
    aaSorting: [
      [9, "desc"]
    ]
  }
  let zeroReportsTable = table.DataTable(dataTableOptions);

  // Move to the first page after user changes par page option
  $('#zero-report-table').on('length.dt', function (e) {
    zeroReportsTable.page(0);
  })

  $('.dropdown-menu-file-listing').on('click', function (e) {
    e.stopPropagation();
  });

  $('#submission_begin_date, #submission_end_date').datepicker({
    autoclose: true
  });

  $('#search-inp').on('keyup', function (e) {
    if (e.code == 'Enter') {
      generalSearch();
    }
  }); ``
  $('#search-files-table-btn').on('click', function () {
    generalSearch();
  });
  $('#advanced-search-files-table-btn').on('click', function (e) {
    e.preventDefault();
    advancedSearch();
  });
  $('#advanced-search-files-table-btn').on('keyup', function (e) {
    if (e.code == 'Enter') {
      e.preventDefault();
      advancedSearch();
    }
  });

  function accountNameSearch(accountNameInput = $('#account_name').val()) {
    zeroReportsTable.columns(0).search(accountNameInput).draw();
  }
  function pmpSearch(pmpSelectInput = $('.search-pmp-select').children("option:selected").val()) {
    zeroReportsTable.columns(1).search(pmpSelectInput).draw();
  }
  function submissionBeginDateSearch(beginDateInput = $('#submission_begin_date').val()) {
    zeroReportsTable.columns(7).search(beginDateInput).draw();
  }
  function submissionEndDateSearch(endDateInput = $('#submission_end_date').val()) {
    zeroReportsTable.columns(8).search(endDateInput).draw();
  }
  function generalSearch(searchInput = $('#search-inp').val()) {
    if (zeroReportsTable == null || zeroReportsTable == undefined) return;
    return zeroReportsTable.search(searchInput).draw()
  };
  function advancedSearch() {

    if (zeroReportsTable == null || zeroReportsTable == undefined) return;

    pmpSearch();
    submissionBeginDateSearch();
    submissionEndDateSearch();
    accountNameSearch();
  };
});