import { Spinner } from 'spin.js';

$(function () {
  $('.datatable').dataTable({
    'sPaginationType': "simple_numbers",
    'sDom': "<'row-fluid'<'span6'l><'span6'f>r>t<'row-fluid'<'span6'i><'span6'p>>",
    'bAutoWidth': false,
    'bDestroy': true
  });
});

let dataTablesTBody = null;
let dataTablesSpinner = null;
let dataTablesSpinnerOpts = {
  lines: 13, // The number of lines to draw
  length: 15, // The length of each line
  width: 5, // The line thickness
  radius: 20, // The radius of the inner circle
  scale: 1, // Scales overall size of the spinner
  corners: 1, // Corner roundness (0..1)
  color: '#000', // CSS color or array of colors
  fadeColor: 'transparent', // CSS color or array of colors
  speed: 2, // Rounds per second
  rotate: 0, // The rotation offset
  animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
  direction: 1, // 1: clockwise, -1: counterclockwise
  zIndex: 2e9, // The z-index (defaults to 2000000000)
  className: 'spinner', // The CSS class to assign to the spinner
  top: '50%', // Top position relative to parent
  left: '50%', // Left position relative to parent
  shadow: '0 0 1px transparent', // Box-shadow for the lines
  position: 'absolute' // Element positioning
};

export const tableStartProcessing = function tableStartProcessing() {
  let dataTables = $('.dataTable');
  if (dataTables.length > 0 && $(dataTables).attr('data-source') != undefined) {
    if (dataTablesSpinner == null) {
      dataTablesTBody = dataTables[0].querySelector("tbody");
      dataTablesSpinner = new Spinner(dataTablesSpinnerOpts);
    }

    dataTablesSpinner.spin(dataTablesTBody);
  }

  return "";
}

export const tableStopProcessing = function tableStopProcessing() {
  if (dataTablesSpinner != null) {
    dataTablesSpinner.stop();
  }
}
